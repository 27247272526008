import React, {Fragment, useEffect, useRef, useState} from "react";
import { ToastContainer, toast } from 'react-toastify';
import { CHAIN_NAMESPACES } from "@web3auth/base";
import { Web3Auth  } from "@web3auth/modal";
import {createAccount, getBalance, cluster, getConnectionConfig, getUserSessionData, backend_api_url} from "./../constants";
import Badge from 'react-bootstrap/Badge';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-bootstrap/Modal';
import ReCAPTCHA from "react-google-recaptcha";

const Support = (props: any) => {

  const descriptionInput = useRef<HTMLTextAreaElement>(null);
  const nameInput = useRef<HTMLInputElement>(null);
  const emailInput = useRef<HTMLInputElement>(null);
  const captchaInput = useRef<HTMLInputElement>(null);

  const captchaRef = useRef<any>(null);

  

  const [showError, setShowError] = useState(false);

  const handleCloseError = () => setShowError(false);
  const handleShowError = () => setShowError(true); 

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true); 
 

  const [mintError, setMintError] = useState("");
  const [mintErrorHeader, setMintErrorHeader] = useState(""); 
  
  const submitSupport = async () => {
    const userData = getUserSessionData();    
    setMintErrorHeader("Notification");
 

    if(nameInput.current?.value === "") {
        setMintError("Please enter your name");
        handleShowError();
        return;
    }
    if(emailInput.current?.value === "") {
        setMintError("Please enter your email");
        handleShowError();

        return;
    }
    if(descriptionInput.current?.value === "") {
      setMintError("Please enter the issue description");
      handleShowError();
        return;
    }
    if(captchaInput.current?.value === "") {
      setMintError("Please enter the captcha code");
      handleShowError();
        return;
    } 

    if(captchaRef.current.getValue() == '') {
      setMintError("Please enter the correct captcha code");
        handleShowError();
        return
    }

    const params = {
        //token: userData.token,
        //secret: userData.secret,
        name: nameInput.current?.value,
        email: emailInput.current?.value,
        description: descriptionInput.current?.value
    
    } 
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(params)
    };  
    fetch(backend_api_url + 'api/v1/support/submit', requestOptions)
        .then(response => response.json())
        .then(data => {
            handleClose();
            if(data.status === 1) {
              setMintError("Support Request Sent");
              handleShowError();
            } else {
              setMintError("Request not sent. Please try again.");
              handleShowError();
            }
        }); 
  }

  const onChangeCaptcha = (value: any) => {
    
  }   
  
useEffect(() => { 
 }
, []);  

    return (
        <Fragment>
        
        <main>
  <section className="headline">
	<h1>Contact Us</h1>
  </section>

  
  <section className="white">
	<div className="padding">
    <p><b>Need help?</b> <a href="#contact" onClick={handleShow}>Send a message to Toybot</a></p>

	  <br /> 
  </div>

    <Modal  className="modal-preview"  show={show} onHide={handleClose} backdrop="static">
          <Modal.Header closeButton={true}>
          <Modal.Title>Contact Toybot</Modal.Title>
          </Modal.Header>
          <Modal.Body>

              <div style={{"textAlign" : "center"}}>
                      <table style={{"border" : 0, "marginLeft" : "0%"}} className="createCollectionTable">
              <tbody>
              

                <tr>
                  <td style={{"padding" : "0%"}} align="right">Full Name</td>
                  <td> 
                    <input style={{"width": "100%"}} type="text" className="textbox" required  ref={nameInput}/>

                  </td>
                </tr> 

                <tr>
                  <td style={{"padding" : "0%"}} align="right">Email</td>
                  <td> 
                    <input style={{"width": "100%"}} type="email" className="textbox" required  ref={emailInput}/>

                  </td>
                </tr>

                <tr>
                  <td style={{"padding" : "0%"}} align="right">Issue/Topic</td>
                  <td> 
                    <textarea   className="about-collection" placeholder="Detailed description" required ref={descriptionInput}></textarea>


                  </td>
                </tr>
                <tr>
                  <td style={{"padding" : "0%"}} align="right">Captcha</td>
                  <td>
                   <ReCAPTCHA ref={captchaRef} sitekey={process.env.REACT_APP_CAPTCHA_KEY}  onChange={onChangeCaptcha} />
                  </td>
                </tr> 
                

              </tbody>
              </table>
            
            <div className="createCollectionSubmit" style={{"textAlign" : "center", "marginTop" : "30px"}}>
        <button type="button" onClick={submitSupport} className="buttonLink btn-custom">SUBMIT</button>
              </div>
              </div>

          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
      </Modal> 


    <Modal  className="modal-preview"  show={showError} onHide={handleCloseError} backdrop="static">
          <Modal.Header closeButton={true}>
          <Modal.Title>{mintErrorHeader}</Modal.Title>
          </Modal.Header>
          <Modal.Body>

              <div style={{"textAlign" : "center"}}>
                  {mintError}
              </div>

          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
      </Modal> 

  </section>
</main>
 


         

        </Fragment>
    );
}
export default Support;