import { Fragment, useEffect, useState } from "react";
import { backend_api_url, getUserSessionData, isUserAdmin } from "./constants";

const ActionsContainer = (props: any) => {

    const [listingJsx, setListingJsx] = useState<JSX.Element[] | null>(null)

    const fetchAllUsers = async (): Promise<any> => {
        
        const userData = getUserSessionData();

        //setCurrentUserName(userData.username);

        const params = {
            token: userData.token,
            secret: userData.secret,
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/all', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    showAllUsers(data.users);
                    
                }
            });
	};
  
    const showAllUsers = async (users: any[]): Promise<any> => {
  
         setListingJsx( users.map( (info, index) => <Fragment key={index + (new Date()).getTime()}> 
            <tr>
                <td>{info.date}</td>
                <td><a  rel="noreferrer" href={"/user/" + info.username} target="_blank">{info.username}</a></td>
                <td>{info.email}</td>

            </tr>
            </Fragment>) );
 
     };
 

	useEffect(() => {
		
        //fetchAllMyGachas();
        if(!isUserAdmin()) {
            window.location.href = '/';
        }
        fetchAllUsers();
	  }, []);

      props.setClassInfo("accountPg");

    return (
        <Fragment>
            <div className="contents">
               <a href="/admin/users">Users</a>&nbsp;|&nbsp;               
               <a href="/admin/creators">Creators</a>&nbsp;|&nbsp;               
               <a href="/admin/collections">Collections</a>&nbsp;|&nbsp;               
               Purchases: <a href="/admin/purchases/credits">Credits</a>&nbsp;-&nbsp;<a href="/admin/purchases/nfts">NFTs</a>&nbsp;|&nbsp;
               <a href="/admin/actions">Actions</a>

                <br /><br /><br />
               <div>
                    <a target="_blank" href="/admin/adjust-credits">Adjust Credits</a>
                    <br />
                    <a target="_blank" href="/admin/promo-codes">Promo Codes</a>
                </div> 
            </div>
                
         
        </Fragment>
    );
}

export default ActionsContainer;