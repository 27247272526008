import React, {Fragment, useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom";
import {cluster, getExplorerUrl, getPrivateKeyBase58, getUserSessionData} from "./constants";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';

const WalletLink = (props : any) => {

    const [wallet, setWallet] = useState<string>("");
    const [walletJsx, setWalletJsx] = useState<JSX.Element | null>(null)
    const [walletExplorerJsx, setWalletExplorerJsx] = useState<JSX.Element | null>(null)
    const [copyJsx, setCopyJsx] = useState<JSX.Element | null>(null)

    const [privateKey, setPrivateKey] = useState<string>("");

    const [value, setValue] = useState<string>("");
    const [copied, setCopied] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [message, setMessage] = useState<string>("");

    const copyNow = () => {
        setCopied(true);
        handleShow();
        setMessage('Copied to clipboard');
        // toast.success("Copied to clipboard", {
        //     className : "success-toast"
        // });
    }
    const getUserWallet = async (provider) => {
        const userData = getUserSessionData();
        

        if(userData) {
            const wallet = props!.custom_wallet ? props!.custom_wallet : userData.wallet;

            const walletDisplay = props.mobile ? wallet.slice(0, 3) + '...' + wallet.slice(-3) : wallet;
 

            setWallet(wallet);
            //setCopyJsx();
            setWalletExplorerJsx( <Fragment>  <a target="_blank" rel="noreferrer" href={getExplorerUrl('address')+wallet+'?cluster='+ cluster}> {walletDisplay} </a> &nbsp; <CopyToClipboard text={wallet} onCopy={copyNow}><i className="fa fa-copy"></i></CopyToClipboard> &nbsp;  </Fragment> );
            setWalletJsx(  <Link to="/wallet"> {walletDisplay} </Link> );
            //const web3authKey = await getPrivateKeyBase58(provider);
            //setPrivateKey(web3authKey);
        } 

    }

    useEffect(() => {
         
        getUserWallet(props.provider);

        if(getUserSessionData() == null || props.provider == null) {
            
                props.loginWeb3Auth();

            return;
        }

    }, []);

    return (
        <Fragment>
            {privateKey}
            {props?.isExternal ? walletExplorerJsx : walletJsx}

            <Modal className="modal-processing" show={show} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton={true}>
                {/* <Modal.Title>Notification</Modal.Title> */}
                </Modal.Header>
                    <Modal.Body>
                    <div style={{ "textAlign" : "center"}}>
                        {message}
                    </div>
        
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal> 
        </Fragment>

    );
}

export default WalletLink;