import React, {Fragment, useEffect, useState, useRef} from "react";
import { Link, useParams } from "react-router-dom";
import { backend_api_url, cluster, getBalance, getCurrentWallet, getUserBalance, getUserBalanceNew, getUserSessionData, isShowLedgerInfo, isShowLedgerInfoNew, wait} from "./constants";
import { PublicKey , Keypair, LAMPORTS_PER_SOL,  Connection, clusterApiUrl } from "@solana/web3.js";
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { ToastContainer, toast } from 'react-toastify';
import { getCurrentWalletPublicKey} from './constants';

import WalletLink from "./WalletLink"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { SystemProgram, Transaction } from '@solana/web3.js';

import { SolanaWallet } from "@web3auth/solana-provider";

    
import { Metaplex, keypairIdentity } from "@metaplex-foundation/js";
import axios from "axios";
import LedgerItem from "./LedgerItem"; 
import LedgerItemNew from "./LedgerItemNew";
 

const WalletActivityNew = (props : any) => {
 
    const { connection } = useConnection();
    const [balance, setBalance] = useState<number>(0);


    const [otherUser, setOtherUser] = useState(false);
    const [solBalance, setSolBalance] = useState<number>(0);

    const [username, setUsername] = useState("");
       
      
    const [isFetching, setIsFetching] = useState(false);
    const [lastSignature, setLastSignature] = useState("");
    const [lastSig, setLastSig] = useState("");

    const [ledgerJsx, setLedgerJsx] = useState<JSX.Element[] | null>([])
    const [ledgerMobileJsx, setLedgerMobileJsx] = useState<JSX.Element[] | null>([])
    const [usernameJsx, setUsernameJsx] = useState<JSX.Element>(null);

    const [renderedElements, setRenderedElements] = useState([]);
    const [renderedElementsMobile, setRenderedElementsMobile] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);

    const [totalItems, setTotalItems] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    //const [allTransactions, setAllTransactions] = useState([]);

    const [loadedData, setLoadedData] = useState([]);
    const [freeMint, setFreeMint] = useState([]);

    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [isEnd, setIsEnd] = useState(false);

    const [loadText, setLoadText] = useState('Load Transactions');
    const [hasLoaded, setHasLoaded] = useState(false);

    const [currentWallet, setCurrentWallet] = useState("");
    //const [numItems, setNumItems] = useState(0);

    const checkTxn = async (lastSigInfo, numItems) => {
      setIsProcessing(true); 

      let lastSig1 = lastSigInfo;
      let allTransactions = [];

      const userData = getUserSessionData();

      
      if(userData) {
        // 'FNwiu2wwAPP9CKmy58i84qXEmdakNkb3pxmyooQxUSCq'; // 
        const walletAddress = otherUser ? currentWallet : getCurrentWallet();
        let url = process.env.REACT_APP_HELIUS_API + 'v0/addresses/'+walletAddress+'/transactions?limit=100&api-key='+process.env.REACT_APP_HELIUS_API_KEY;
        let newUrl = url;
        while (true) {
          
          if (lastSig1) {
            newUrl = url + `&before=${lastSig1}`;
          }
          try {
            const response = await fetch(newUrl+"&t="+new Date());
            

            const transactions = await response.json();
            // console.log(response);

            // console.log(transactions);

            if (transactions && transactions.length > 0) {
              //console.log("Fetched transactions: ", transactions);

              for(var i in transactions) {
                allTransactions.push(transactions[i]);
              }
              lastSig1 = transactions[transactions.length - 1].signature;
              setLastSig(lastSig1);
              break;
            } else if (transactions && transactions.error && transactions.error.indexOf('Failed to find events within the search period') !== -1) {
              wait(3000);
              console.log('retrying');
            } else {
              console.log("No more transactions available.");
              break;
            }
          } catch (error) {
             console.log(error);
             wait(2000);
          }
          //break;
        }
      }
      //  console.log('Initial retrieval');
      //  console.log(allTransactions);
      
      setupPaginatedItems(allTransactions, numItems, lastSig1);

    };
      

  const loadAllLedgerNew = async (allTransactionsList, numItems, lastSig1) => {

      let newTransactionList = []; 
      
      let isShow = false;
      
      let isCont = false;
      let newItems = null;
      let sig = "";
       

          if(allTransactionsList.length === 0) {
             setIsEnd(true);
          }else{

            for(var i in allTransactionsList) {
                isShow = false;
                
        
                  const transactionData = (allTransactionsList[i]);
                    

                  isShow = isShowLedgerInfoNew(transactionData);
                    
                  if(isShow) {
                    newTransactionList.push(transactionData);
                  } 

                  sig = allTransactionsList[i].signature;
        
            }
          }
          

          console.log('Filtered');
          console.log(newTransactionList);

    const userData = getUserSessionData(); 
 
    //setIsProcessing(false);
 
    const elem = newTransactionList.map( (transaction) => <LedgerItemNew freeMint={freeMint} ownwallet={currentWallet} key={transaction.signature} type="desktop"   transaction={transaction} signature={transaction.signature}/>);
    const elem1 = newTransactionList.map( (transaction) => <LedgerItemNew freeMint={freeMint} ownwallet={currentWallet} key={transaction.signature} type="mobile"   transaction={transaction} signature={transaction.signature}/>);

    setLedgerJsx((prevElements) => [...prevElements, ...elem]);
    setLedgerMobileJsx((prevElements) => [...prevElements, ...elem1] );

    setIsProcessing(false);

    let remItems = numItems - newTransactionList.length

    console.log("num items " + numItems + " rem items " + remItems);

    if(remItems > 0) {
      //setNumItems(remItems);
      if(sig !== '')
        checkTxn(sig, remItems);
    } else if(allTransactionsList.length > 0){
      //setNumItems(20);
    }
  }

  

  const loadMoreItems = () => {
    if(!hasLoaded) {
      setIsProcessing(true);
      checkTxn("", 20);
      setHasLoaded(true);
      setLoadText('Load More');
    }else{
      checkTxn(lastSig, 20);
      //loadAllLedgerNew(allTransactions);

    }
  } 
 
    const setupPaginatedItems = (allTransactionsList, numItems, lastSig1) => {
      //const pageCount = Math.ceil(allTransactionsList.length / itemsPerPage);
      //setTotalItems(allTransactionsList.length);
      //setPageCount(pageCount);
      loadAllLedgerNew(allTransactionsList, numItems, lastSig1);
      //setAllTransactions(allTransactionsList); 
    }

        let paramsFrom = useParams();
    
   const reloadUserBalance = async() => {
      const userData1 = getUserSessionData();

      let username1 =  userData1.username;

      if(paramsFrom!.username && paramsFrom!.username !== userData1.username) {
          username1 =  paramsFrom!.username;
      }
      

      await getUserBalanceNew(setBalance, username1);

  }

  const loadWalletBalanceCustom = async (pubKey : string) => {
        //console.log(pubKey);
        // console.log('loadSolBalance');
        const pubkey =  new PublicKey(pubKey);

        const balance = await getBalance(pubkey, connection);
        setSolBalance(balance);
    }

  const getFreeNfts = (username) => {
    const userData = getUserSessionData();

    const paramsInfo = {
        username: username,
        token: userData ? userData.token : '',
        secret: userData ? userData.secret : '',
    }



    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(paramsInfo)
    };
    fetch(backend_api_url + 'api/v1/users/free-mints', requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.status === 1) {
                setFreeMint(data.mint);

                if(userData.username != username) {
                  loadWalletBalanceCustom(data.primary_wallet);
                }
                setCurrentWallet(data.primary_wallet);
 
                if(otherUser && data.isAdmin === 0) {
                    window.location.href = '/';
                }
            }
        });
  }
    useEffect(() => {
        if(!getUserSessionData()) { 
            window.location.href = "/";
        }else{ 
          const userData = getUserSessionData();

          
          if(userData) {

              let username1 =  userData.username;
  
              if(paramsFrom!.username && paramsFrom!.username !== userData.username) {
                   
                username1 =  paramsFrom!.username;
                setOtherUser(true);
                   
              }
              
              setUsername(username1);
              setUsernameJsx(<a href={"/wallet/" + username1}>{username1}</a>);
 
              reloadUserBalance();
              getFreeNfts(username1);
          }
        }
    }, []);

    props.setClassInfo('walletActivityPg');
 
   


    return (
        <Fragment>

<div className="contents">

<h5 className="outer">{usernameJsx} &gt;&gt; Wallet Activity</h5>

<div className="br"></div>

  { (props.isAdmin || !otherUser) &&
    <p><b>SOLs : {otherUser ? solBalance.toFixed(4) : props.solBalance.toFixed(4)}<br/>Credits : ${balance.toFixed(0)}</b></p>
}
  <br/>

<div className="tblDesktop">
    {/* cellpadding="0" cellspacing="0" */}
<table className="walletActivityTable"  style={{ "borderSpacing" : 0}} >
  <tbody>
    <tr>
      <td align="center"><b>TIME</b></td>
      <td align="center"><b>FROM</b></td>
      <td align="center"><b>TO</b></td>
      <td align="center"><b>TYPE</b></td>
      <td align="center"><b>AMOUNT</b></td>
      <td align="center"><b>TRANSACTION</b></td>
    </tr>	

    {ledgerJsx}
 
  </tbody>
</table>
</div>

<div className="tblMobile">
    {/* cellpadding="2" cellspacing="0" */}

{/* {renderedElementsMobile} */}

    {ledgerMobileJsx}

    
</div>		

{isProcessing && <p className="bigger-font" style={{"textAlign": "center"}}>Processing...</p>}
{!isProcessing && !isEnd && <div className="bigger-font" style={{"textAlign": "center"}}><button className="buttonLink btn-logout " onClick={loadMoreItems}>{loadText}</button> This may take time.</div>}


</div>

        
        </Fragment>

    );
}

export default WalletActivityNew;