import React, {Fragment, useEffect, useState, useRef} from "react";
import { Link, useParams } from "react-router-dom";
import { backend_api_url, cluster, getBalance, getCurrentWallet, getExplorerUrl, getUserBalance, getUserBalanceNew, getUserSessionData, isShowLedgerInfo, isShowLedgerInfoNew, wait} from "./constants";
import { PublicKey , Keypair, LAMPORTS_PER_SOL,  Connection, clusterApiUrl } from "@solana/web3.js";
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { ToastContainer, toast } from 'react-toastify';
import { getCurrentWalletPublicKey} from './constants';

import WalletLink from "./WalletLink"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { SystemProgram, Transaction } from '@solana/web3.js';

import { SolanaWallet } from "@web3auth/solana-provider";

    
import { Metaplex, keypairIdentity } from "@metaplex-foundation/js";
import axios from "axios";
import LedgerItem from "./LedgerItem"; 
import LedgerItemNew from "./LedgerItemNew";
 

const Purchases = (props : any) => {
  
    const [balance, setBalance] = useState<number>(0);
  
    const [ledgerJsx, setLedgerJsx] = useState<JSX.Element[] | null>([])
    const [ledgerMobileJsx, setLedgerMobileJsx] = useState<JSX.Element[] | null>([])
    const [usernameJsx, setUsernameJsx] = useState<JSX.Element>(null);
 
    const [isProcessing, setIsProcessing] = useState(false);
    const [otherUser, setOtherUser] = useState(false);
    const [solBalance, setSolBalance] = useState<number>(0);

    const [username, setUsername] = useState("");
      
     const { connection } = useConnection();
 
   
    let paramsFrom = useParams();

    const reloadUserBalance = async() => {
        const userData1 = getUserSessionData();

        let username1 =  userData1.username;

        if(paramsFrom!.username && paramsFrom!.username !== userData1.username) {
            username1 =  paramsFrom!.username;
        }
        

        await getUserBalanceNew(setBalance, username1);

    }

    const loadWalletBalanceCustom = async (pubKey : string) => {
        //console.log(pubKey);
        // console.log('loadSolBalance');
        const pubkey =  new PublicKey(pubKey);

        const balance = await getBalance(pubkey, connection);
        setSolBalance(balance);
    }
  const loadAllPurchases = (username) => {
    setIsProcessing(true);

    const userData = getUserSessionData();
 
    let params = {
        username: username,
        token: userData ? userData.token : '',
        secret: userData ? userData.secret : '',
    }


    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(params)
    };
    fetch(backend_api_url + 'api/v1/users/get-txn', requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.status === 1) {
                
                if(userData.username != username) {
                    loadWalletBalanceCustom(data.primary_wallet);
                }

                if(otherUser && data.isAdmin === 0) {
                    window.location.href = '/';
                }


                renderTableData (data.txns); 
                
                setIsProcessing(false);

            }
        });
        
  }

  
  useEffect(() => { 
          const userData1 = getUserSessionData();
          if(userData1) {
  
              props.loginWeb3Auth(); 
              
              let username1 =  userData1.username;
  
              if(paramsFrom!.username && paramsFrom!.username !== userData1.username) {
                  if(true || props.isAdmin) {
                      username1 =  paramsFrom!.username;
                      setOtherUser(true);
                  } else {
                      
                      console.log(paramsFrom!.username);
                      console.log(userData1.username);
                      console.log(props.isAdmin);
  
                      //alert('redirecting');
                      window.location.href = "/";
                  }
              }
              
              reloadUserBalance(); 
              setUsername(username1);
              setUsernameJsx(<a href={"/user/" + username1}>{username1}</a>);
              

              loadAllPurchases(username1);

          }else{
              window.location.href = "/";
          }
  
      }, []);



  const renderTableData = (txns) => {

    setLedgerJsx( txns.map( (info, index) => <Fragment key={index + (new Date()).getTime()}> 
    <tr>
        <td align="center">{info.date_created_display}</td>
        <td align="center">{info.isMint === 1 ? <Fragment>Mint <a target="_blank"   rel="noreferrer" href={getExplorerUrl('address')+info.address+'/?cluster='+cluster}>NFT</a> - <a target="_blank" rel="noreferrer" href={"/collection/" + info.collectionAddress}>{info.collectionName}</a></Fragment> : info.type}</td>


        <td align="center">{info.amount}</td>

    </tr>
    </Fragment>) );

setLedgerMobileJsx( txns.map( (info, index) => <Fragment key={index + (new Date()).getTime()}> 
<table className="walletActivityTable"  style={{ "borderSpacing" : 0}}>
<tbody>
<tr>
    <td width="40%"><b>DATE</b></td>
    <td width="60%">{info.date_created_display	}</td>
</tr>
<tr>
    <td width="40%"><b>TYPE</b></td>
    <td width="60%">{info.isMint === 1 ? <Fragment>Mint <a target="_blank"   rel="noreferrer" href={getExplorerUrl('address')+info.address+'/?cluster='+cluster}>NFT</a> - <a target="_blank" rel="noreferrer" href={"/collection/" + info.collectionAddress}>{info.collectionName}</a></Fragment> : info.type}</td>
</tr>
<tr>
    <td width="40%"><b>CREDITS</b></td>
    <td width="60%">{info.amount}</td>
</tr> 
</tbody>
</table>
</Fragment>) );

  }
    

    props.setClassInfo('walletActivityPg');
 
   


    return (
        <Fragment>

<div className="contents">

<h5 className="outer">{usernameJsx} &gt;&gt; Purchases</h5>

<div className="br"></div>

  {/* <p><b>SOLs : {props.solBalance.toFixed(4)}<br/>Credits : ${balance.toFixed(2)}</b></p> */}

  { (props.isAdmin || !otherUser) &&
    <p><b>SOLs : {otherUser ? solBalance.toFixed(4) : props.solBalance.toFixed(4)}<br/>Credits : ${balance.toFixed(0)}</b></p>
}

  <br/>

<div className="tblDesktop">
    {/* cellpadding="0" cellspacing="0" */}
<table className="walletActivityTable"  style={{ "borderSpacing" : 0}} >
  <tbody>
    <tr>
      <td align="center"><b>DATE</b></td>
      <td align="center"><b>TYPE</b></td>
      <td align="center"><b>CREDITS</b></td>
    </tr>	

    {ledgerJsx}
 
  </tbody>
</table>
</div>

<div className="tblMobile">
  

    {ledgerMobileJsx}

    
</div>		

{isProcessing && <p className="bigger-font" style={{"textAlign": "center"}}>Processing...</p>}
 

</div>

        
        </Fragment>

    );
}

export default Purchases;