import { Fragment, useEffect, useState } from "react";
import { backend_api_url, cluster, getExplorerUrl, getUserSessionData, isUserAdmin } from "./constants";

const PurchaseListCreditsContainer = (props: any) => {

    const [listingJsx, setListingJsx] = useState<JSX.Element[] | null>(null)

    const fetchInfo = async (): Promise<any> => {
        
        const userData = getUserSessionData();

        //setCurrentUserName(userData.username);

        const params = {
            token: userData.token,
            secret: userData.secret,
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/purchases-credits', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    showInfo(data.credits);
                    
                }
            });
	};
  
    const showInfo = async (lists: any[]): Promise<any> => {
 
          //console.log(allListings)
         setListingJsx( lists.map( (info, index) => <Fragment key={index + (new Date()).getTime()}> 
            <tr>
                <td>{info.date}</td>
                <td><a  rel="noreferrer" href={"/user/" + info.username} target="_blank">{info.username}</a></td>
 

                <td>{info.email}</td>
                <td>{info.credit}</td>
                <td>{info.price}</td>

                <td><a  rel="noreferrer" href={"/wallet/"+info.username} target="_blank">View Wallet</a></td>
 

            </tr>
            </Fragment>) );
 
     };
 

	useEffect(() => {
		
        //fetchAllMyGachas();
        if(!isUserAdmin()) {
            window.location.href = '/';
        }
        fetchInfo();
	  }, []);

      props.setClassInfo("accountPg");

    return (
        <Fragment>
            <div className="contents">
               <a href="/admin/users">Users</a>&nbsp;|&nbsp;               
               <a href="/admin/creators">Creators</a>&nbsp;|&nbsp;               
               <a href="/admin/collections">Collections</a>&nbsp;|&nbsp;               
               Purchases: <a href="/admin/purchases/credits">Credits</a>&nbsp;-&nbsp;<a href="/admin/purchases/nfts">NFTs</a>&nbsp;|&nbsp;
               <a href="/admin/actions">Actions</a>

               <table className="walletActivityTable admin-infos"  style={{ "borderSpacing" : 0}} >
                <tbody>
                    <tr>
                    <td align="center"><b>TIME</b></td>
                    <td align="center"><b>USER</b></td>
                    <td align="center"><b>EMAIL</b></td>
                    <td align="center"><b>AMOUNT</b></td>
                    <td align="center"><b>PRICE</b></td>
                    <td align="center"><b>WALLET</b></td> 

                    </tr>	

                    {listingJsx}
                
                </tbody>
                </table>
            </div>
                
         
        </Fragment>
    );
}

export default PurchaseListCreditsContainer;