import { Fragment, useEffect, useState } from "react";
import { backend_api_url, getUserSessionData, isUserAdmin } from "./constants";

const CreatorContainer = (props: any) => {

    const [listingJsx, setListingJsx] = useState<JSX.Element[] | null>(null)

    const fetchAllUsers = async (): Promise<any> => {
        
        const userData = getUserSessionData();

        //setCurrentUserName(userData.username);

        const params = {
            token: userData.token,
            secret: userData.secret,
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/all-creators', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    showAllUsers(data.users);
                    
                }
            });
	};

    const changePublish = (id, e) => {
        // console.log(id);
        // console.log(e.target.value);

        const userData = getUserSessionData();

        //setCurrentUserName(userData.username);

        const params = {
            token: userData.token,
            secret: userData.secret,
            id: id,
            value: e.target.value
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/update-publish', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    ;//showAllUsers(data.users);
                    
                }
            });
    }
    const changePending = (id, e) => {
        // console.log(id);
        // console.log(e.target.value);

        const userData = getUserSessionData();

        //setCurrentUserName(userData.username);

        const params = {
            token: userData.token,
            secret: userData.secret,
            id: id,
            value: e.target.value
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/update-approve', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    ;//showAllUsers(data.users);
                    
                }
            });
    }
  
    const showAllUsers = async (users: any[]): Promise<any> => {
   
         setListingJsx( users.map( (info, index) => <Fragment key={index + (new Date()).getTime()}> 
            <tr>
                <td>{info.date}</td>
                <td><a  rel="noreferrer" href={"/user/" + info.username} target="_blank">{info.username}</a></td>
                <td>{info.email}</td>
                <td>
                    <select onChange={(e) => changePending(info.id, e)}>
                        {
                            info.isApproved === false && <Fragment>
                                    <option selected value="1">Pending</option>
                                    <option value="2">Approved</option>    
                            </Fragment>
                        }
                        {
                            info.isApproved === true && <Fragment>
                                    <option value="1">Pending</option>
                                    <option selected value="2">Approved</option>    
                            </Fragment>
                        }
                    </select>    
                </td>
                <td>
                    <select onChange={(e) => changePublish(info.id, e)}>
                        {
                            info.is_publish === false && <Fragment>
                                    <option selected value="0">No</option>
                                    <option value="1">Yes</option>    
                            </Fragment>
                        }
                        {
                            info.is_publish === true && <Fragment>
                                    <option value="0">No</option>
                                    <option selected value="1">Yes</option>    
                            </Fragment>
                        }
                    </select>    
                </td>
            </tr>
            </Fragment>) );
 
     };
 

	useEffect(() => {
		
        //fetchAllMyGachas();
        if(!isUserAdmin()) {
            window.location.href = '/';
        }
        fetchAllUsers();
	  }, []);

      props.setClassInfo("accountPg");

    return (
        <Fragment>
            <div className="contents">
               <a href="/admin/users">Users</a>&nbsp;|&nbsp;               
               <a href="/admin/creators">Creators</a>&nbsp;|&nbsp;               
               <a href="/admin/collections">Collections</a>&nbsp;|&nbsp;               
               Purchases: <a href="/admin/purchases/credits">Credits</a>&nbsp;-&nbsp;<a href="/admin/purchases/nfts">NFTs</a>&nbsp;|&nbsp;
               <a href="/admin/actions">Actions</a>

               <table className="walletActivityTable admin-infos"  style={{ "borderSpacing" : 0}} >
                <tbody>
                    <tr>
                    <td align="center"><b>TIME</b></td>
                    <td align="center"><b>CREATOR</b></td>
                    <td align="center"><b>EMAIL</b></td>
                    <td align="center"><b>REQUEST</b></td>
                    <td align="center"><b>PUBLISH</b></td>
                    </tr>	

                    {listingJsx}
                
                </tbody>
                </table>
            </div>
                
         
        </Fragment>
    );
}

export default CreatorContainer;