import React, { Fragment, useEffect, useRef, useState } from "react";
import { backend_api_url, cluster, getExplorerUrl, getUserSessionData, settingsSlider } from "./constants";
import WalletLink from "./WalletLink";
import RecentlyMinted from "./RecentlyMinted";
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import DraftPasteProcessor from 'draft-js/lib/DraftPasteProcessor';
import { convertToHTML } from 'draft-convert';
import linkifyHtml from 'linkify-html';
import { EditorState, ContentState } from 'draft-js'
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal'; 
import Editor from '@draft-js-plugins/editor'; 
import { useParams } from "react-router-dom";
import Slider from "react-slick";


const UserView = (props: any) => {

    const [listingJsx, setListingJsx] = useState<JSX.Element[] | null>(null)
    const [listingItems, setListingItems] = useState<any[] | null>(null)
    const [username, setUsername] = useState<String>("");
    const [wallet, setWallet] = useState<String>("");
    const [status, setStatus] = useState(8);
    const [isOver, setIsOver] = useState(0);
    const [id, setId] = useState(0);

    const [hideMinted, setHideMinted] = useState(false);
    const [hideCollection, setHideCollection] = useState(true);

    const [avatar, setAvatar] = useState<string>("/avatar-bak.png");
	const [about, setAbout] = useState<string>("");
    
    const generateRandomKey =(index) => {
        return new Date().getTime() + index;
      }

    const [renderedElements, setRenderedElements] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isAdmin, setIsAdmin] = useState(0);
    const [isCreator, setIsCreator] = useState(0);

    const [isRequestCreate, setIsRequestCreate] = useState(-1);

    const [showClear, setShowClear] = useState(false);
    const handleCloseClear = () => setShowClear(false);
    const handleShowClear = () => setShowClear(true);

    const requestCreate = () => {
        handleShowClear();
    }
    
    const paramsMain = useParams();

    useEffect(() => {
        const delay = 100; // Adjust the delay (in milliseconds) between rendering elements

        const timer = setTimeout(() => {
        if (listingJsx && currentIndex < listingJsx.length) {
            setRenderedElements((prevElements) => [...prevElements, listingJsx[currentIndex]]);
            setCurrentIndex(currentIndex + 1);
        }
        }, delay);

        return () => clearTimeout(timer);
    }, [currentIndex, listingJsx]);
    
    const fetchAllUserListings = async (username): Promise<any> => {
        
        setUsername(username);

        const userData = getUserSessionData();

        let items = window.location.href.split("/") ;
        let alternateUsername = items.length > 0 ? items[items.length-1] : '';

        const paramsInfo = {
            username: username,
            username1: alternateUsername,

            token: userData ? userData.token : '',
            secret: userData ? userData.secret : '',
        }



        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(paramsInfo)
        };
        fetch(backend_api_url + 'api/v1/users/gachas-by-user', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    
                    setIsAdmin(data.userInfo.isAdmin);
                    setUsername(data.userInfo.username);
                    setWallet(data.userInfo.wallet);
                    setStatus(data.userInfo.status);
                    setIsOver(data.userInfo.isOver);
                    setId(data.userInfo.id);

                    setIsCreator(data.userInfo.is_request_collection > 0 ? 1 : 0);
                    setIsRequestCreate(data.userInfo.is_request_collection);

                    if(data.userInfo.avatar) {
                        setAvatar(data.userInfo.avatar);
                    }
                    if(data.userInfo.about) {
                        setAbout(data.userInfo.about);
                    }

                    setListingItems(data.machines);
                    
                    if(data.machines.length === 0) {
                        ;//setHideCollection(true);
                    }else{
                        if(data.userInfo.isAdmin === 1) {
                        setListingJsx( data.machines.map( (gacha, index) => <Fragment key={generateRandomKey(index)}>
                        {gacha.is_draft === 1 ? <div className='machine-data'><a href={'/edit-collection/'+gacha.id + '/' + gacha.user_id} className="imgSliderDetails-1 collection-info">
                            <img alt="" src={gacha.avatar} /></a><span style={{"textAlign":"center", "display" : "block"}}>{gacha.name}</span><a href={'/edit-collection/'+gacha.id + '/' + gacha.user_id}>Edit Settings</a></div> : 
                            <div className='machine-data'><a href={'/collection/'+gacha.candy_machine_address} className="imgSliderDetails-1 collection-info"><img alt="" src={gacha.avatar} /></a><br />
                        <span style={{"textAlign":"center", "display" : "block"}}>{gacha.name}</span>
                        <a href={'/collection/'+gacha.candy_machine_address + '/settings'}>View Settings</a>
                        <span style={{"textAlign":"center", "display" : "block"}}>Created: {gacha.date_created_display}</span></div> }
                    </Fragment>) );
                        } else {
                            setListingJsx( data.machines.map( (gacha, index) => 
                            <Fragment key={generateRandomKey(index)}><a style={{"textAlign" : "center"}}  
                            href={'/collection/'+gacha.candy_machine_address} className="imgSliderDetails-1">
                                <img alt="" src={gacha.avatar} /><br />{gacha.name}</a></Fragment>) );
                        }
                    }

                }else{
                    showToastError("Invalid User");
                    window.location.href = "/";

                }
            });
	};

    const requestCreateCollection = () => {
        const userData = getUserSessionData();
 
        let items = window.location.href.split("/") ;
        let alternateUsername = items.length > 0 ? items[items.length-1] : '';
 

        const params = {
            token: userData.token,
            secret: userData.secret,
            username: username,
            username1: alternateUsername,

        }

        

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/request-create-by-admin', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) { 
                    setIsRequestCreate(1); 
                    handleCloseClear();
                }
            });
      }
      

    const showToastError = (message) => {
        toast.error((<Fragment>{message}<div className="toast-close-section"><button className="link-button toast-close">close message</button></div></Fragment>),{
            autoClose: false,
            closeButton: false
          });
    }

    const changePending = (id, e) => {
        // console.log(id);
        // console.log(e.target.value);

        const userData = getUserSessionData();

        //setCurrentUserName(userData.username);

        const params = {
            token: userData.token,
            secret: userData.secret,
            id: id,
            value: e.target.value
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/update-status', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    ;//showAllUsers(data.users);
                    
                }
            });
    }

	useEffect(() => {
		fetchAllUserListings(paramsMain.userName);
	  }, []);

      props.setClassInfo("accountPg");

    return (
        <Fragment>
            <div className="contents">

                <h5 className="outer">{username}</h5>
                {
                    props.isAdmin &&  (status === 0 || status === 2 || status === 3) &&
                <div style={{"paddingTop": "20px", "color" : "red"}}>
                    

                    {
                        status === 0  && props.followupRequested === 0 && <Fragment>New account under review. You can only accept tokens and buy NFTs for now.  <a href="#followup" onClick={props.confirmFollowup}>Follow-up</a></Fragment>
                    }
                    {
                        status === 0  && props.followupRequested === 1  && <Fragment>New account under review. You can only accept tokens and buy NFTs for now.  Follow-up requested.</Fragment>
                    } 
                    {
                        status === 2  && props.reviewRequested === 0 && <Fragment>Account locked. You can't send tokens out nor buy NFTs. <a href="#request" onClick={props.confirmRequest}>Request review</a></Fragment>
                    }
                    {
                        status === 2  && props.reviewRequested === 1 && <Fragment>Account locked. You can't send tokens out nor buy NFTs. Review requested.</Fragment>
                    }
                    {
                        status === 3  && props.followupRequested === 0 && <Fragment>Account under review. You can only accept tokens and buy NFTs for now.  <a href="#followup" onClick={props.confirmFollowup}>Follow-up</a></Fragment>
                    }
                    {
                        status === 3  && props.followupRequested === 1  && <Fragment>Account under review. You can only accept tokens and buy NFTs for now.  Follow-up requested.</Fragment>
                    }

                </div>
                }
                {
                    props.isAdmin &&  
                    <div style={{"paddingTop": "20px"}}>
                        <select onChange={(e) => changePending(id, e)}>
                            {
                                isOver === 1 && <Fragment>
                                        <option disabled value="0">New</option>
                                        <option selected={status === 1} value="1">Unlocked</option>   
                                        <option selected={status === 2} value="2">Locked</option>   
                                        <option selected={status === 3} value="3">Under Review</option>    
                                </Fragment>
                            }
                            {
                                isOver === 0 && <Fragment>
                                        <option selected={status === 1} value="0">New</option>
                                        <option selected={status === 1} value="1">Unlocked</option>   
                                        <option selected={status === 2} value="2">Locked</option>   
                                        <option selected={status === 3} value="3">Under Review</option>    
                                </Fragment>
                            }
                        </select>    
                    </div>
                }
                {
                    props.isAdmin &&  
                <div className="collectionsDiv" style={{"marginTop":"-25px", "padding" : "15px 0px"}}>
                    
                    
                    <div style={{"paddingTop": "20px"}}>

                    {/* <a  rel="noreferrer" href={getExplorerUrl('address')+wallet+'?cluster='+ cluster} target="_blank">View Wallet</a> */}

                    <a  rel="noreferrer" href={"/wallet/"+username} target="_blank">View Wallet</a>

                    
                    </div>

                    {
                        isRequestCreate == 0 && <Fragment><div style={{"paddingTop": "20px"}}><a href="#request" onClick={requestCreate}>Request to Create</a></div></Fragment>
                    }
                    {
                        isRequestCreate == 1 && <Fragment><div style={{"paddingTop": "20px"}}>Creator Request Pending</div></Fragment>
                    }
                    {
                        isRequestCreate == 2 && <Fragment><div style={{"paddingTop": "20px"}}>Creator Request Approved</div></Fragment>
                    }
                </div>
                }
                <div className="rightSideContent aboutCreator username">

                <div className="leftSideDiv">
                    <a href="#" className="createrImg"><img src={avatar} alt=""/></a>

                </div>

                <p><b>ABOUT : </b><span className="about-us" dangerouslySetInnerHTML={{ __html: about }} /></p>

                {/* <p className="wallet-data"><a href="/wallet">Wallet Address</a> : {<span className="account-wallet"><WalletLink loginWeb3Auth={props.loginWeb3Auth} provider={props.provider} isExternal={false}/></span>}</p> */}


                </div>

                <br/><br/>

                { !hideCollection && 
                <div className="collections recentlyMinted collectionsCreated">
                <h5>COLLECTIONS CREATED</h5>
                {/* <a href="/create-collection" className="buttonLink createLink">CREATE</a> */}

                {/* <div id="ImageSlider">
                    <div id="ImageSliderDiv" className="slider slider-collections">
                    
                    {
                listingJsx && 
                <Slider  {...settingsSlider}>
                        {
                            listingJsx
                        }
                </Slider>
            } 



                    </div>
                </div>  */}

                    <div className="collectionsDiv">
                    
                                {renderedElements}
                            
                    </div>
                
                </div>
                }
                <br/><br/><br/><br/>
                {
                    !hideMinted && 
                <div className="collections recentlyMinted nftsMinted">
                <h5>NFTS MINTED</h5>
                    {
                        username && <RecentlyMinted isRefresh={true} setHideMinted={setHideMinted} type="others" candyMachineAddress='' username={username} useCarousel={false}></RecentlyMinted>
                    }
                </div>
                }
                </div>

                

                <Modal className="modal-preview" show={showClear} onHide={handleCloseClear} backdrop="static">
                    <Modal.Header closeButton={true}>
                    <Modal.Title>Request to Create</Modal.Title>
                    </Modal.Header>
                        <Modal.Body>
                        <div style={{ "textAlign" : "center"}}>
                        Are you sure you want to request to create a collection?
                        </div>

                        <div style={{ "textAlign" : "center", "marginTop" : "30px"}}> 
                            <button onClick={handleCloseClear} className="buttonLink btn-generic">CANCEL</button>

                            <button onClick={requestCreateCollection} className="buttonLink btn-generic">CONFIRM</button>
                            </div>
                            
                        </Modal.Body>
                        <Modal.Footer>
                        </Modal.Footer>
                    </Modal>
                    
        </Fragment>
    );
}

export default UserView;