import { Fragment, useEffect, useRef, useState } from "react";
import { backend_api_url, getUserSessionData, isUserAdmin } from "./constants";
import { Typeahead } from "react-bootstrap-typeahead";
import Modal from 'react-bootstrap/Modal';

const AdjustCreditsContainer = (props: any) => {

    const typeInput = useRef<HTMLSelectElement>(null);
    const amountInput = useRef<HTMLInputElement>(null);

    const [options, setOptions] = useState<any[]>([]);
 
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    
    const [showAdjust, setShowAdjust] = useState(false);
    const handleCloseAdjust = () => setShowAdjust(false);
    const handleShowAdjust = () => setShowAdjust(true);


    const [singleSelections, setSingleSelections] = useState([]);

    const fetchAllUsers = async (): Promise<any> => {
        
        const userData = getUserSessionData();

        //setCurrentUserName(userData.username);

        const params = {
            token: userData.token,
            secret: userData.secret,
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/all-creators', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    setOptions(data.dataList); 

                }
            });
	};
 
   
    
    const confirmAdjustment = ( ) => {
        // console.log(id);
        // console.log(e.target.value);
        setHasError(false);

        if(singleSelections.length === 0) {
            setHasError(true);
            setErrorMessage("Please select creator");
            return;
        }

        if( parseFloat(amountInput.current.value) > 0) {
            ;
        } else {
            setHasError(true);
            setErrorMessage("Amount is required");
            return;
        } 

        const userData = getUserSessionData();

        //setCurrentUserName(userData.username);

        const params = {
            token: userData.token,
            secret: userData.secret,
            id: singleSelections[0].id,
            type: typeInput.current.value,
            amount: parseFloat(amountInput.current.value)
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/update-credits', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    ;//showAllUsers(data.users);
                    handleShowAdjust();
                }
            });
    }
 
	useEffect(() => {
		
        //fetchAllMyGachas();
        if(!isUserAdmin()) {
            window.location.href = '/';
        }
        fetchAllUsers();
	  }, []);


      const state = {
        disabled: false,
        dropup: false,
        flip: false,
        highlightOnlyResult: true,
        minLength: 0,
        open: undefined,
      };

      props.setClassInfo("accountPg");

    return (
        <Fragment>
            <div className="contents">
               <a href="/admin/users">Users</a>&nbsp;|&nbsp;               
               <a href="/admin/creators">Creators</a>&nbsp;|&nbsp;               
               <a href="/admin/collections">Collections</a>&nbsp;|&nbsp;               
               Purchases: <a href="/admin/purchases/credits">Credits</a>&nbsp;-&nbsp;<a href="/admin/purchases/nfts">NFTs</a>&nbsp;|&nbsp;
               <a href="/admin/actions">Actions</a>

                <br /><br /><br />
               <div>
               <h5 className="outer">Adjust Credits</h5> 

                {hasError && <Fragment><div style={{"textAlign" : "center", "color" : "red"}} className="alert alert-danger">{errorMessage}</div></Fragment>}
               <table style={{"border" : 0}} className="createCollectionTable">
      <tbody>
      
  
        <tr>
          <td  align="right">Creator</td>
          <td> 
              <Typeahead
                {...state} 
                id="basic-typeahead-single"
                labelKey="name"
                onChange={setSingleSelections}
                options={options}
                placeholder="Choose Creator"
                selected={singleSelections}

              /> 
          </td>
        </tr> 

        <tr>
          <td  align="right">Type</td>
          <td> 
              <select  ref={typeInput} >
                <option value="1">Add</option>
                <option value="2">Subtract</option>
              </select>
          </td>
        </tr> 
 
        <tr>
            <td align="right">Amount</td>
            <td>
            <input type="text" className="textbox" required  ref={amountInput}/>
            </td>	 
        </tr>
         

      </tbody>
    </table>

    <div className="createCollectionSubmit" style={{"textAlign" : "center", "marginTop" : "30px"}}>
 <button type="button" onClick={confirmAdjustment} className="buttonLink btn-custom">CONFIRM</button>

</div>  
                </div> 
            </div>
                
         
            <Modal  className="modal-preview"  show={showAdjust} onHide={handleCloseAdjust} backdrop="static">
                <Modal.Header closeButton={true}>
                <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div>
                        Operation Successful
                    </div>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal> 
            
        </Fragment>
    );
}

export default AdjustCreditsContainer;