import { Fragment, useEffect, useRef, useState } from "react";
import { backend_api_url, checkUserBalanceForMint, chunkArray, clearPromoCode, cluster, getClientOnRampSecret, getClientStripeSecret, getExplorerUrl, getPaypalClientId, getPrivateKeyBase58, getPromoStatus, getUserBalance, getUserSessionData, isUserLoggedIn, mintNewGacha, saveUserReload, stripe_pub_key } from "./constants";
import { useParams } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import WalletLink from "./WalletLink";
import { Keypair } from "@solana/web3.js";
import { Metaplex, PublicKey, keypairIdentity } from "@metaplex-foundation/js";
import { useConnection } from "@solana/wallet-adapter-react";
import axios from "axios";
import RecentlyMinted from "./RecentlyMinted";
import OnRamp from "./OnRamp";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const ViewGacha = (props: any) => {

    let params = useParams();
 

    const [allNftImages, setAllNftImages] = useState<string[] | any>();
    const [contentVal, setContentVal] = useState<string>('');
    const [candyMachineAddress, setCandyMachineAddress] = useState<string>('');
    const [avatar, setAvatar] = useState<string>('');
    const [banner, setBanner] = useState<string>('');
    const [userInfo, setUserInfo] = useState<any>(null);
    const [showUserInfo, setShowUserInfo] = useState(false);
    const [nftCount, setNftCount] = useState<string>('');
    const [price, setPrice] = useState<string>('');
    const [priceDisplay, setPriceDisplay] = useState<string>('');

    const [tokenAccepted, setTokenAccepted] = useState<string>('');
     
    
    let captureCallback;

    const [isReadyMint, setIsReadyMint] = useState(0);
    const [itemsInfoTextDisplay0, setItemsInfoTextDisplay0] = useState('');
    const [itemsInfoTextDisplay1, setItemsInfoTextDisplay1] = useState('');
    const [itemsInfoTextDisplay2, setItemsInfoTextDisplay2] = useState('');

    const [numOfDesigns, setNumOfDesigns] = useState(0);

    const [edition, setEdition] = useState<string>('');
    const [numOfMinted, setNumOfMinted] = useState(0);
    const [mintedText, setMintedText] = useState('On Sale');

    const [numCredits, setNumCredits] = useState(0);

    const [balance, setBalance] = useState<number>(0);

    const [usernameJsx, setUsernameJsx] = useState<JSX.Element>(null);
    const [isRefresh, setIsRefresh] = useState(false);

    
    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showMintOk, setShowMintOk] = useState(false);
    const handleCloseMintOk = () => setShowMintOk(false);
    const handleShowMintOk = () => setShowMintOk(true);

    const [showConfirmLogin, setShowConfirmLogin] = useState(false);
    const handleCloseConfirmLogin = () => setShowConfirmLogin(false);
    const handleShowConfirmLogin = () => setShowConfirmLogin(true);

    const [gachaEdition, setGachaEdition] = useState<string>('');
    const [gachaWallet, setGachaWallet] = useState<string>('');
    const [collectionName, setCollectionName] = useState<string>('');

    
    const [showGacha, setShowGacha] = useState(false);
    const handleCloseGacha = () => setShowGacha(false);
    const handleShowGacha = () => setShowGacha(true);

    const [mintImage, setMintImage] = useState<string>('/images/mint-animation.gif');


    const [showPreview, setShowPreview] = useState(false);
    const handleClosePreview = () => setShowPreview(false);
    const handleShowPreview = () => setShowPreview(true);

    const { connection } = useConnection();

    const [previewImageName, setPreviewImageName] = useState<JSX.Element>(null);
    const [previewImageUrl, setPreviewImageUrl] = useState("");
    const [previewImageDesc, setPreviewImageDesc] = useState("");
    const [previewCollectionName, setPreviewCollectionName] = useState<JSX.Element>(null);
    const [propertiesJsx, setPropertiesJsx] = useState<JSX.Element[] | null>(null)
    const [usernameJsx1, setUsernameJsx1] = useState<JSX.Element>(null);

    const [mintError, setMintError] = useState("");
    const [mintErrorSub, setMintErrorSub] = useState("");

    const [mintErrorHeader, setMintErrorHeader] = useState("");

    
    const [showError, setShowError] = useState(false);
    const handleCloseError = () => setShowError(false);
    const handleShowError = () => setShowError(true);
    

    const [showErrorReview, setShowErrorReview] = useState(false);
    const handleCloseErrorReview = () => setShowErrorReview(false);
    const handleShowErrorReview = () => setShowErrorReview(true);
    

    const [secret, setSecret] = useState<string>('');
    const [showStripe, setShowStripe] = useState(false);
    const handleCloseStripe = () => setShowStripe(false);
    const handleShowStripe = () => setShowStripe(true);
 

    const [showCapture, setShowCapture] = useState(false);
    const handleCloseCapture = () => setShowCapture(false);
    const handleShowCapture = () => setShowCapture(true);


    const [showPromoMessage, setShowPromoMessage] = useState(false);
    const handleClosePromoMessage = () => setShowPromoMessage(false);
    const handleShowPromoMessage = () => setShowPromoMessage(true);

    const [promoMessage, setPromoMessage] = useState("");

    const [showBuyCrypto, setShowBuyCrypto] = useState(false);

    const [reloadBalance, setReloadBalance] = useState("100");
    const amountInput = useRef<HTMLInputElement>(null);

    const [showStripeReloadAmount, setShowStripeReloadAmount] = useState(false);
    const handleCloseStripeReloadAmount = () => setShowStripeReloadAmount(false);
    const handleShowStripeReloadAmount = () => setShowStripeReloadAmount(true);


    const [showClear, setShowClear] = useState(false);
    const handleCloseClear = () => setShowClear(false);
    const handleShowClear = () => setShowClear(true);

    const stripePromise = loadStripe(stripe_pub_key);
  
    const [isMobile, setIsMobile] = useState(true);

    const [promoCode, setPromoCode] = useState("");
    const [promoStatus, setPromoStatus] = useState("");

    const [hasPromo, setHasPromo] = useState(false);
    const [isPromoActive, setIsPromoActive] = useState(false);

    const options = {
        clientSecret: secret,
    };

    const openStripe = async () => {
        const isUserLogin = isUserLoggedIn2();

        if(isUserLogin === false || getUserSessionData() == null) {
            if(hasPromo) {
                handleShowClear();
                props.loginWeb3Auth();
            } else {
                handleShowStripeReloadAmount();
            }
        } else {

            const resp = await checkUserBalanceForMint(candyMachineAddress, promoCode);

            if(resp.userStatus === 2) {
                props.setReviewRequested(resp.is_review_pending);
                
                handleCloseMintOk() 
                handleClose();
                handleCloseError();

                handleShowErrorReview();
                     
            }else if(resp.hasBalance === 1) {

                openStripeNow(candyMachineAddress); 
                
            } else {

                await getUserBalance(setBalance);
                handleShowStripeReloadAmount();

            }
        }
    }
    const openStripeNow1 = async () => {
        setReloadBalance(amountInput.current!.value);
        await openStripeNow(candyMachineAddress);
    }
    const openStripeNow = async (gachaAddress) => {

        const isUserLoggedIn1 = isUserLoggedIn2() && getUserSessionData() != null;

        const resp = await getClientStripeSecret(gachaAddress, amountInput.current ? amountInput.current!.value : 10, isUserLoggedIn1, promoCode);

        if(resp.userStatus === 2) {
            let errorMessage = 'You are not allowed to mint NFTs. Please contact support.';
            let header = 'Notification';
            
            setMintErrorMessage(errorMessage, header);
        }else if(resp.availableForMint === 1) {
            if(resp.hasPromo == 1 && resp.hasClaimed == 1) {
                setPromoMessage("You've already redeemed a free NFT");
                handleShowPromoMessage();
                
                // setPromoCode('');
                // setHasPromo(false);
                // setIsPromoActive(false);

            }else if(resp.hasPromo == 1 && (resp.promoStatus == 0 || resp.promoStatus == 2 || resp.promo_is_completed == 1 || (resp.promoStatus == 1 && resp.allowPromoClaim == 0 ) ) ) {
                let errorMessage = resp.promoStatus == 0 ? 'Sorry this promo has paused' : 'Sorry this promo has ended';
                let header = 'Notification';
                
                setMintErrorMessage(errorMessage, header);
                
                // setPromoCode('');
                // setHasPromo(false);
                // setIsPromoActive(false);
            }else if(resp.hasBalance === 1) {

                handleCloseStripe();
                // showSuccessPayment();
                await mintGachaNow('');
                
            } else {
                handleCloseStripeReloadAmount();
                setSecret(resp.clientSecret); 
                handleShowStripe();
            }
        } else if(resp.availableForMint === 2) {
            let errorMessage = 'Toy Machine is not ready to mint.';
            let header = 'Notification';
            
            setMintErrorMessage(errorMessage, header);
        } else {
            let errorMessage = 'Sold Out. All NFTs have been minted.';
            let header = 'Sold Out';
            
            setMintErrorMessage(errorMessage, header);

        }
    }

    const reloadUserBalanceSave = async(txnId, type) => {
 
        if(isUserLoggedIn()) {
            await saveUserReload(reloadBalance, txnId, type);
            
            //await openStripe();
        } else {
           alert("Invalid Process");
        }
    
    } 
    
    const showSuccessPayment = () => {
      handleCloseStripe();

      handleShowError();
      setMintError("Purchase Successful");
      setMintErrorSub("You’ve paid $"+reloadBalance+" to add "+reloadBalance+" credits to your account.");
      setMintErrorHeader('Notification')
      handleShowMintOk(); 

        // setMintErrorHeader('Notification');
        // setMintError('Payment Successful');
    //   handleShowError(); 
        // setMintErrorMessage("Payment Successful", "Notification");
    }

    const showMintedNft = async (resp) => {
        
        if(resp) {
            
            showNftDataNew(resp);
        }

    }

    const showCollectionLink = async(collectionAddress, collectionName, address, nftName) => {

        const params = {
            address: collectionAddress
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/machine/get-info-by-mint-address', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                     
                    setPreviewCollectionName(<a href={"/collection/" + data.address}>{data.name}</a>);

                    //setPreviewImageName(<a target="_blank" className="all-caps"  rel="noreferrer" href={getExplorerUrl('address')+address+'?cluster='+ cluster}>{ data.name.toUpperCase() + ' - ' + nftName.toUpperCase()}</a>);
                    setPreviewImageName(<a target="_blank" className="all-caps"  rel="noreferrer" href={getExplorerUrl('address')+address+'?cluster='+ cluster}>{nftName.toUpperCase()}</a>);

                }else{
                    //not in the system, so display address to external url 
                    setPreviewCollectionName(<Fragment>{collectionName}</Fragment>);

                }
            });
            return;
    }

    const showCollectionLinkNew = async(collectionAddress, collectionName, address, nftName) => {

        setPreviewCollectionName(<a href={"/collection/" + collectionAddress}>{collectionName}</a>);
        //setPreviewImageName(<a target="_blank" className="all-caps"  rel="noreferrer" href={getExplorerUrl('address')+address+'?cluster='+ cluster}>{ collectionName.toUpperCase() + ' - ' + nftName.toUpperCase()}</a>);
        setPreviewImageName(<a target="_blank" className="all-caps"  rel="noreferrer" href={getExplorerUrl('address')+address+'?cluster='+ cluster}>{  nftName.toUpperCase()}</a>);

    }
    

    const showNftDataNew = (resp) => {
        
        //if(resp.is_promo_claimed == 1) {
            // setPromoCode('');
            // setHasPromo(false);
            // setIsPromoActive(false);
        //}

        // resp.dataInfo.metadata_uri
        let nftName = resp.dataInfo.name.split(".")[0];

        let address = '';

        showCollectionLinkNew(resp.dataInfo.collectionAddress, resp.dataInfo.collectionName, address, nftName);


        setPreviewImageName(<div>{nftName}</div>);
        setPreviewImageUrl(resp.dataInfo.image_uri);

        let userData = getUserSessionData();

        setUsernameJsx1(<a href={"/user/" + userData.username}>{userData.username}</a>);

        const requestOptions = {
            method: 'GET'
        };
        fetch(resp.dataInfo.metadata_uri, requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.attributes) {
                        //console.log('show att');
                        const attributes = Array.isArray(data.attributes) ? data.attributes : JSON.parse(data.attributes);  
                        //console.log(attributes);
                        const properties = chunkArray(attributes, 2);
                         
                        const chunkedJSX = [];
    
                        for (let i = 0; i < properties.length; i ++) {
                            let elem = properties[i];
    
                            if(elem.length === 2) {
                                chunkedJSX.push(
                                    <div className='row-elem' key={i}>
                                        {elem[0] &&  <div className="custom-column">{elem[0].trait_type} : <span className="prop-value">{elem[0].value}</span></div>} 
                                        {elem[1] &&  <div className="custom-column">{elem[1].trait_type} : <span className="prop-value">{elem[1].value}</span></div>} 
                                    </div>
                                );
                            }else{
                                chunkedJSX.push(
                                    <div className='row-elem' key={i}>
                                        {elem[0] &&  <div className="custom-column">{elem[0].trait_type} : <span className="prop-value">{elem[0].value}</span></div>} 
                                    </div>
                                );
                            }
                        }
                        setPropertiesJsx (chunkedJSX);                        
                }
                if(data.description) {
                    setPreviewImageDesc(data.description);
                }
            });

        
    handleShowPreview(); 
    }

    const  mintGacha = async(e) => {
        // if(getUserSessionData() == null || props.provider == null) {
        //     props.loginWeb3Auth();
        //     return;
        // }
        
        if(hasPromo && promoStatus == "0") {
             
            setPromoMessage("Sorry this promo is paused");
            handleShowPromoMessage();


        } else if(hasPromo && promoStatus == "2") {
            setPromoMessage("Sorry this promo has ended");
            handleShowPromoMessage();
        } else {

            let userData = getUserSessionData();
            if(userData) {

                const resp = await getPromoStatus(candyMachineAddress, promoCode);
 
                if(resp.hasPromo == 1 && resp.hasClaimed == 1) {
                    setPromoMessage("You've already redeemed a free NFT");
                    handleShowPromoMessage();
                    return;
                } 
            }

            handleCloseError();
            handleShowClear();
        }
    }

    

    const confirmMint = () => {
        handleCloseClear();
        openStripe();
    }

    const isUserLoggedIn2 = () => {
		if(props.provider === null) {
			return false;
		}
		return true;
	}
    const mintGachaNow = async(paymentIntentId: any) => {

        //return;

        setShowBuyCrypto(false); 
        
        setIsRefresh(false);

        if(props.provider === null) {
            props.loginWeb3Auth();
        } 
        
        //const web3authKey = await getPrivateKeyBase58(props.provider);

        const userData = getUserSessionData();
   
        const params1 = {
            candyMachineAddress: candyMachineAddress,
              
            receiver: userData.wallet,

            paymentIntentId: paymentIntentId

        }; 
        handleShow();
        const isSuccessData = await mintNewGacha(params1, params.showToastError, setMintErrorMessage, setShowBuyCrypto, handleClose, showSuccessfulMint, promoCode);
        
    }

    const showSuccessfulMint = (successData) => {
        showMintedNft(successData);
        setIsRefresh(true);
    }

    const setMintErrorMessage = (message, header) => {
        if(message) {

            handleCloseMintOk()
            setMintError(message);
            setMintErrorSub("");
            setMintErrorHeader(header)
            handleClose();
            handleShowError();
        }
    }
 
    const viewGacha = async (address, claim, promo): Promise<any> => {

        //console.log("promo code : ");
        //console.log(promo);
        // if(params!.promoCode) {
        //     setPromoCode();
        // }

        setCandyMachineAddress(address);
        const userData = getUserSessionData();

        //setCurrentUserName(userData.username);

        let params = {
            token: userData ? userData.token : '',
            secret: userData ? userData.secret : '',
            candy_machine_address: address,
            promoCode: promo ? promo : ''
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/machine/info', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {

                    if(data.invalid_promo == 1) {
                        window.location.href = '/collection/'+address;
                    }

                    if(data.has_promo == 1) {
                        setPromoCode(data.promo_code);
                        setHasPromo(true);
                        setPromoStatus(data.promo_status);
                        if(data.is_promo_active == 1) {
                            setIsPromoActive(true);
                        }
                    }

                    setIsReadyMint(data.gacha.is_ready_mint);
                    setItemsInfoTextDisplay0(data.itemsInfoTextDisplay0);
                    setItemsInfoTextDisplay1(data.itemsInfoTextDisplay1);

                    setItemsInfoTextDisplay2(data.itemsInfoTextDisplay2);

                    setContentVal(data.gacha.description);
                    setAvatar(data.gacha.avatar);
                    setNftAssets(data.nfts);
                    setGachaEdition(data.gacha.edition)
                    //setGachaWallet(data.gacha.gacha_wallet_priv_key.substr(3));
                    setBanner(data.gacha.banner_photo);
                    setCollectionName(data.gacha.name)
                    setUserInfo(data.user);
                    setShowUserInfo(true);
                    setNftCount(data.collectionSize);
                    setEdition(data.gacha.edition.toUpperCase());
                    setPrice(data.gacha.price);
                    setPriceDisplay('$'+data.gacha.price);
                    setNumCredits(parseInt(data.gacha.price, 10));
                    setTokenAccepted(data.gacha.token_accepted);
                    setNumOfMinted(data.numOfMinted);

                    if(data.numOfMinted === data.collectionSize) {
                        setMintedText("SOLD OUT");
                    } else {
                        if(data.is_admin == 1) {
                            setMintedText("ON SALE – MINTED: " + data.numOfMinted)
                        } else {
                            setMintedText("ON SALE")

                        }
                    } 
                    setNumOfDesigns(data.nftDesigns);
                    setUsernameJsx(<a href={"/user/" + data.user.username}>{data.user.username}</a>);
 
                    if(data.is_admin === 0 && data.gacha.is_available === 0) {
                        if(data.is_same_user === 0)
                            window.location.href = "/";
                    }
                    //     console.log(claim);
                    // if(claim == 1) {
                        //claimGacha();
                    // }
                }
            });
	};

    const claimNow = (userData, pendingInfo) => {
        //const pendingInfo = JSON.parse(localStorage.getItem("pendingData"+process.env.REACT_APP_WEB_AUTH_CHAIN_NET)  as string);
        //try to reddem
        if(localStorage.getItem("pendingData"+process.env.REACT_APP_WEB_AUTH_CHAIN_NET)) {
            //console.log(captureCallback);
            //handleShowStripe();
            captureCallback();
            return;
        }
     
        /*
        let params = {
            token: userData ? userData.token : '',
            secret: userData ? userData.secret : '',
            candy_machine_address: pendingInfo.gacha,
            txnId: pendingInfo.txn,
            uuid: pendingInfo.uuid,
            gacha: pendingInfo.gacha
        }


        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/claim-pending-txn', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    localStorage.removeItem("pendingData"+process.env.REACT_APP_WEB_AUTH_CHAIN_NET);
                    //claim gacha
                    if(data.gachaExist == 1) {

                        setMintErrorMessage("Payment Successful", "Notification");

                        //openStripeNow(pendingInfo.gacha);
                    }
                }else{
                    
                    alert("Invalid Process")
                }
            }); 
        */
    }
    const claimGacha =  () => {
         

        if(isUserLoggedIn() && localStorage.getItem("pendingData"+process.env.REACT_APP_WEB_AUTH_CHAIN_NET)) {
            const userData = getUserSessionData();
            const pendingInfo = JSON.parse(localStorage.getItem("pendingData"+process.env.REACT_APP_WEB_AUTH_CHAIN_NET)  as string);

            claimNow(userData, pendingInfo)
        }
    }

    const setNftAssets = async (nfts : any) => {
        //console.log(nfts);

        
        // const allImages = await Promise.all(
		// 	nfts.map((asset: any) => {
        //         return {'url': asset.arweave_url, 'name' : asset.name};
                
                
		// 	})
 

		// );
        
            setAllNftImages(nfts)

    }

    const loginNow = () => {
       
        
        let paypalId = localStorage.getItem("pendingPaypalId"+process.env.REACT_APP_WEB_AUTH_CHAIN_NET);
 
        if(paypalId) {
            props.loginWeb3Auth(doPaypalCapture);
        } else {
            handleCloseStripeReloadAmount();
            handleShowClear();
            props.loginWeb3Auth(handleCloseConfirmLogin);
        }
    } 
    const hideAndLoginBeforeCapture = (callback) => {
        captureCallback = callback;
        //handleCloseStripe();

        const pendingData = {
            txn: '',
            uuid: '',
            gacha: candyMachineAddress
        }

        localStorage.setItem("pendingData"+process.env.REACT_APP_WEB_AUTH_CHAIN_NET, JSON.stringify(pendingData));

        if(!isUserLoggedIn2() || getUserSessionData() == null) {
            handleShowConfirmLogin();
        }

        //props.loginWeb3Auth(claimNow);
    }

    const updateImage = () => {
        setMintImage("/images/mint.png");
    }
    const resetImage = () => {
        setMintImage("/images/mint-animation.gif");
    }
    const showDesigns = async () => {
        // console.log("Show all Designs " + params.address);
        handleShowGacha();
    }
  
    const initialOptions = {
        clientId: getPaypalClientId(),
        currency: "USD",
        intent: "authorize",
    };
    
    const onCreateOrder = (data,actions) => {
    
        
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: reloadBalance, // "8.99",
                    },
                },
            ],
        });
    }
    
    const doPaypalCapture = async () => {
           
          let paypalId =             localStorage.getItem("pendingPaypalId"+process.env.REACT_APP_WEB_AUTH_CHAIN_NET);
  
          //console.log("capture paypal order id" + paypalId);
          //mint
          handleCloseConfirmLogin();
          handleCloseStripe();

          handleShowCapture();
          
          if(paypalId) {
            //capture the payment

            let params = {
                id: paypalId
            }
    
    
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify(params)
            };
            fetch(backend_api_url + 'api/v1/users/capture-paypal', requestOptions)
                .then(response => response.json())
                .then(data => {
                    if(data.status === 1) {
                        
                        localStorage.removeItem("pendingData"+process.env.REACT_APP_WEB_AUTH_CHAIN_NET);
                        localStorage.removeItem("pendingPaypalId"+process.env.REACT_APP_WEB_AUTH_CHAIN_NET);
                        
                        handleCloseCapture();
                        
                        //reloadUserBalanceSave(paypalId, 'paypal'); 

                        saveUserReload(reloadBalance, paypalId, 'paypal');
                        //await openStripe();
                        showSuccessPayment();

           

                        //mintGacha(null);
                    }else{
                        
                        alert("Invalid Process")
                    }
                }); 


          }
 
     
    } 

    const onApproveOrder = (data,actions) => {

        return actions.order.authorize().then((details) => {
            // const name = details.payer.name.given_name;
            //console.log(details);
            // alert(`Transaction completed by ${name}`);
            
            //console.log(details.id);
            //console.log(details.status); 
            //paypalId = details.id;

            localStorage.setItem("pendingPaypalId"+process.env.REACT_APP_WEB_AUTH_CHAIN_NET, details.id);

            if(!isUserLoggedIn()) {
                //console.log("hiding, to show login");
                //props.hideAndLoginBeforeCapture(doConfirm);
    
                hideAndLoginBeforeCapture(doPaypalCapture);
                  
            } else {
                doPaypalCapture();
            }
    
            
        });
        
    }

    const requestReview = () => {
        handleCloseErrorReview();
        props.confirmRequest();
    }
    useEffect(() => {
        if(params.address) {
            let isMobile = false;
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
                navigator.userAgent
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(
                navigator.userAgent.substr(0, 4)
                )
            )
                isMobile =  true;
            else 
                isMobile = false;
            
            setIsMobile(isMobile);
            
            viewGacha(params.address, params.claim, params!.promoCode);

        }
    }, []); 

    props.setClassInfo("collectionsPg");

    return (
        <Fragment>

<div id="MintBanner">
        {
            isReadyMint === 0 && <div className='info-overlay'>{itemsInfoTextDisplay0}<br /><br />{itemsInfoTextDisplay1}<br /><br />{itemsInfoTextDisplay2}<br /></div>
        }
		<img className="view-banner-image" src={banner} alt="" />

		<div id="MintBannerDiv">
           <div className="collectionLinks">
			  <a href="#edition" className="buttonLink href-no-hover">{edition} EDITION</a> 
			  <a href="#price" className="buttonLink href-no-hover">PRICE: {hasPromo ? "Free" : priceDisplay }</a>
			  <a href="#collectionSize" className="buttonLink href-no-hover">COLLECTION SIZE ({nftCount})</a>
			  <a href="#minted" className="buttonLink href-no-hover">{mintedText}</a>
		   </div>

		   <div className="mintButton">
              <img onTouchStart={updateImage} onTouchEnd={resetImage} onMouseOver={updateImage} onMouseOut={resetImage}  src={mintImage} className="mint-button" onClick={mintGacha} alt=""/>
		   </div>
		</div>
	  </div>
	  

{/* <button onClick={handleShowStripe}>Show Stripe</button>
<button onClick={handleCloseStripe}>Hide Stripe</button> */}

	  <div className="contents">

		<div className="rightSideContent collectionDetails">
		  
		  <div className="leftSideDiv">
			<a href="#"><img alt="" src={avatar}/></a>
		  </div>	
		  <div className="rightSideDiv">
            <h5>{collectionName}</h5>

            <p><b>CREATOR : </b>{usernameJsx}</p>

            <p><b>ABOUT : </b><span className="about-us" dangerouslySetInnerHTML={{ __html: contentVal }} /></p>

            <p><a href="#designs" onClick={showDesigns}>See Designs ({numOfDesigns})</a></p>
            <br/>
          </div>
		  {/* <p><a href="/wallet">Wallet Address</a> : {<span className="account-wallet"><WalletLink loginWeb3Auth={props.loginWeb3Auth} provider={props.provider} isExternal={false}/></span>}</p> */}
		</div>
		  
		<div className={!isMobile ? "collections recentlyMinted" : "collections ig recentlyMinted"}>
		  <h5>RECENTLY MINTED</h5>

		  {/* <div id="ImageSlider">
			<div id="ImageSliderDiv" className="slider slider-minted">

                {
                    candyMachineAddress && <RecentlyMinted candyMachineAddress={candyMachineAddress} username='' useCarousel={false}></RecentlyMinted>
                }
                

			</div>
		   </div>   */}
		  
           {
                    candyMachineAddress && <RecentlyMinted isRefresh={isRefresh} candyMachineAddress={candyMachineAddress} username='' limit="8" useCarousel={false} isViewGacha={true}></RecentlyMinted>
                }
		</div>

		<h5 className="outer">ABOUT THE CREATOR</h5> 

		<div className="rightSideContent aboutCreator">
            { showUserInfo &&
            <Fragment>
                <div className="leftSideDiv">
                    
                        <a href={'/user/'+userInfo.username}><img alt="" src={userInfo.avatar}/></a>
                    
                </div>
                <div className="rightSideDiv">
                    <p><span className="about-us" dangerouslySetInnerHTML={{ __html: userInfo.about }} /></p>
                </div>
          </Fragment>
            }
		</div>

	  </div>

                 
        <Modal className="modal-processing" show={show} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton={false}>
            <Modal.Title>Processing...</Modal.Title>
            </Modal.Header>
            <Modal.Body>
 
            <div className="progressBar"><div></div></div>
 
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal> 

        <Modal className="modal-error" show={showError} onHide={handleCloseError} backdrop="static">
            <Modal.Header closeButton={true}>
            <Modal.Title>{mintErrorHeader}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ "textAlign" : "center"}}>
 
                <div>
                    {mintError}
                </div>
                <div style={{"marginBottom" : "30px"}}>
                    {mintErrorSub}
                </div>

                <div className="sendNftName" style={{"textAlign" : "center"}}>
                    {
                        showMintOk && <button onClick={mintGacha} className="buttonLink btn-generic">OK</button>
                    }
                    {
                        !showMintOk && <button onClick={handleCloseError} className="buttonLink btn-generic">Close</button>
                    }
                </div>
                
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal> 


        <Modal className="modal-error" show={showErrorReview} onHide={handleCloseErrorReview} backdrop="static">
            <Modal.Header closeButton={true}>
            <Modal.Title>Notification</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ "textAlign" : "center"}}>
 
                <div>
                {
                    props.reviewRequested === 0 && <Fragment>Account locked. You are not allowed to buy NFTs.  <br /><a href="#request" onClick={requestReview}>Request review</a></Fragment>
                }
                {
                    props.reviewRequested === 1 && <Fragment>Account locked. You are not allowed to buy NFTs.  <br />Review requested.</Fragment>
                } 
                </div> 

                <div style={{ "textAlign" : "center", "marginTop" : "20px"}}> 
                    <button onClick={handleCloseErrorReview} className="buttonLink btn-generic">CLOSE</button>
                </div>
                 
                
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal> 


        <Modal className="modal-preview" show={showGacha} onHide={handleCloseGacha}
  size="lg"
  aria-labelledby="contained-modal-title-vcenter"
  centered>
        <Modal.Header closeButton>
        <Modal.Title>Designs</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
                <div>
                    <div>
                        <div className="uploadPatternDiv gacha-designs" >

                        {
                            allNftImages && allNftImages.map( (asset , index : number) => {
                                return asset && <div key={index + (new Date()).getTime()}><img className='asset-image-new 12' alt=""  src={asset.arweave_url}/><br />{asset.nft_name.split(".")[0]}</div>;
                            })
                        }
                            
                        </div>
                        
                        
                        <div className="sendNftName" style={{"textAlign" : "center"}}>
                            <button onClick={handleCloseGacha} className="buttonLink btn-generic">Close</button>
                        </div>
                    </div>
                </div>
        </Modal.Body>
        <Modal.Footer> 
        </Modal.Footer>
    </Modal>


    <Modal className="modal-preview" show={showPreview} onHide={handleClosePreview}
  size="sm"
  aria-labelledby="contained-modal-title-vcenter"
  centered>
        <Modal.Header closeButton={true}>
        <Modal.Title>{previewImageName}</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
                <div id='NftDetail' style={ {"padding" : "10px", "background" : '#ffffff', "margin" : "0px"} }>
                    <img alt="" src={previewImageUrl}/>
                    <br/><br/>
                    <p>OWNED BY : <span>{usernameJsx1}</span></p>
                    { previewCollectionName && <Fragment><p className="pull-left">COLLECTION : {previewCollectionName}</p></Fragment>}
                     
                    <p className="pull-left">DESCRIPTION : <span className="full-text" dangerouslySetInnerHTML={{ __html: previewImageDesc }} /></p>
                    

                    <p className="pull-left">PROPERTIES<br/></p>
                        <div className="custom-row">
                            {
                                propertiesJsx ? propertiesJsx : 'N/A'
                            }
                        </div> 
                    <br />
 
                </div>
        </Modal.Body>
        <Modal.Footer> 
        </Modal.Footer>
    </Modal>
      
    <Modal className="modal-payment" size="sm" show={showStripeReloadAmount} onHide={handleCloseStripeReloadAmount} backdrop="static">
        <Modal.Header closeButton={true}>
        <Modal.Title>Buy Credits
        </Modal.Title>
        </Modal.Header>
            <Modal.Body>
            <div style={{ "textAlign" : "center"}}>

                <div id='SendToken'>
                <span style={{"fontSize":"12px", "position" : "relative", "top" : "-10px"}}>You currently have {balance.toFixed(0)} credit(s). 1 credit = $1</span>
                        <form name="frmSendTokens" id="frmSendTokens" method="get" action="#">

                            <table style={ {"border" : 0}} className="sendTokenTable">
                            <tbody>
                            <tr>
                                <td align="right">Amount</td>
                                
                                <td align="left">
                                <input type="text" className="textbox" required ref={amountInput} defaultValue={100} />
                                </td>	 
                            </tr>

                        
                            </tbody>
                            </table>
                        </form>
                        <button className="buttonLink btn-generic" onClick={openStripeNow1} >PURCHASE NOW</button>

                        {!props.hasUserInfo &&  <div style={{"marginTop" : "10px"}}><br />Have credits? <a onClick={loginNow} href="#">Login</a></div>}
                    
                </div> 

            </div>

            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
    </Modal>
    
    <Modal className={showConfirmLogin ? "modal-payment main-payment opacity-hidden" : "modal-payment"} size="sm" show={showStripe} onHide={handleCloseStripe} backdrop="static">
        <Modal.Header closeButton={true}>
        {/* <Modal.Title>Notification</Modal.Title> */}
        </Modal.Header>
            <Modal.Body>
            <div style={{ "textAlign" : "center"}}>
               
                    <div style={{"textAlign": "left"}}>Buy Credits: ${reloadBalance}</div>

                    {
                            <PayPalScriptProvider options={initialOptions}>
                                <PayPalButtons style={{ layout: "horizontal" }} 
                                fundingSource="paypal"
                                createOrder={(data, actions) => onCreateOrder(data, actions)}
                                onApprove={(data, actions) => onApproveOrder(data, actions)}
                                />
                            </PayPalScriptProvider>
                    }
                    <div style={{ "textAlign" : "center", "margin" : "20px"}}>
                        OR
                    </div>
                    {
                        secret && 
                        <Elements options={options} stripe={stripePromise}>
                            <CheckoutForm hideAndLoginBeforeCapture={hideAndLoginBeforeCapture} saveUserReload={saveUserReload} reloadBalance={reloadBalance} showSuccessPayment={showSuccessPayment} mint={reloadUserBalanceSave}/>
                        </Elements> 
                    }
            </div>

            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
    </Modal>

    <Modal className="modal-payment" size="sm" show={showConfirmLogin} onHide={handleCloseConfirmLogin} backdrop="static">
        <Modal.Header closeButton={true}>
        <Modal.Title>Signup / Login</Modal.Title>
        </Modal.Header>
            <Modal.Body>
            <div style={{ "textAlign" : "center"}}>
            Store your credits and NFTs in a Toybot account.
            </div>

            <div style={{ "textAlign" : "center", "marginTop" : "30px"}}> 
                <button onClick={loginNow} className="buttonLink btn-generic">CONTINUE</button>
              </div>

            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
    </Modal>


    <Modal  className="modal-error"  show={showClear} onHide={handleCloseClear} backdrop="static">
          <Modal.Header closeButton={true}>
          <Modal.Title>Confirm</Modal.Title>
          </Modal.Header>
          <Modal.Body>

              <div style={{ "textAlign" : "center"}}>
              Are you sure you want to mint this NFT for {hasPromo ? "free" : numCredits + ' credits'} ?
              </div>

              <div style={{ "textAlign" : "center", "marginTop" : "30px"}}> 
                <button onClick={handleCloseClear} className="buttonLink btn-generic">CANCEL</button>

                <button onClick={confirmMint} className="buttonLink btn-generic">CONFIRM</button>
              </div>
              
          
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
      </Modal>

      <Modal  className="modal-error"  show={showCapture} onHide={handleCloseCapture} backdrop="static">
          <Modal.Header closeButton={true}>
          <Modal.Title>Confirm</Modal.Title>
          </Modal.Header>
          <Modal.Body>

              <div style={{ "textAlign" : "center"}}>
                Processing PayPal Payment...
              </div>

              <div style={{ "textAlign" : "center", "marginTop" : "30px"}}> 
                <button onClick={handleCloseCapture} className="buttonLink btn-generic">CLOSE</button>
              </div>
              
          
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
      </Modal>

      <Modal  className="modal-error"  show={showPromoMessage} onHide={handleClosePromoMessage} backdrop="static">
          <Modal.Header closeButton={true}>
          <Modal.Title>Notification</Modal.Title>
          </Modal.Header>
          <Modal.Body>

              <div style={{ "textAlign" : "center"}}>
                {promoMessage}
              </div>

              <div style={{ "textAlign" : "center", "marginTop" : "20px"}}>
                <a href={"/collection/" + candyMachineAddress}>Buy an NFT instead</a>

              </div>
              <div style={{ "textAlign" : "center", "marginTop" : "30px"}}> 
                <button onClick={handleClosePromoMessage} className="buttonLink btn-generic">CLOSE</button>
              </div>
              
          
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
      </Modal>

        </Fragment>
    );
}

export default ViewGacha;